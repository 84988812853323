import { Component } from '@angular/core';
import {NgForm} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-business-digitally',
  templateUrl: './business-digitally.component.html',
  styleUrls: ['./business-digitally.component.scss']
})

export class BusinessDigitallyComponent {

  public contactuses:any;
  public httpClient: any;
  public contactusSuccess: any;
  public contactusFail: any;

  constructor(private api: ApiService, httpClient: HttpClient){
    this.httpClient = httpClient
  }

  submitInquiry(event: Event) {
    let inquiriesBody = {
        "data": {
          "user_fullname": (<HTMLInputElement> document.getElementById("fullName")).value,
          "business_name": (<HTMLInputElement> document.getElementById("companyName")).value,
          "when_business_starts": (<HTMLInputElement> document.getElementById("since")).value,
          "user_rule": (<HTMLInputElement> document.getElementById("rule")).value,
          "enhance_or_build": (<HTMLInputElement> document.getElementById("enhanceBuild")).value,
          "message": (<HTMLInputElement> document.getElementById("requirements")).value,
          "business_email": (<HTMLInputElement> document.getElementById("email")).value,
          "business_phone": (<HTMLInputElement> document.getElementById("phone")).value,
        }
    };
    this.contactusFail = ""
    this.contactusSuccess = ""
    const headers = new HttpHeaders({'inquiries-Type': 'application/json'});
    this.httpClient.post('34.125.213.156:1337/api/inquiries',
    inquiriesBody, headers).subscribe((data)=>{
      this.contactusSuccess = 'Thank You!'
    },
    (error) => this.contactusFail = (error.error.error.message.replace("business_email", "Email")))
  }

}
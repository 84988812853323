import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage-one',
  templateUrl: './homepage-one.component.html',
  styleUrls: ['./homepage-one.component.scss']
})
export class HomepageOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

    public setting:any;
    public contactuses:any;
    public httpClient: any;
    public contactusSuccess: any;
    public contactusFail: any;

    constructor(private api: ApiService, httpClient: HttpClient) {
        this.httpClient = httpClient
        this.api.getsetting().subscribe(data=>{
            this.setting=data['data']
        })
    }

    onSubmit(contactus: NgForm) {
        let contactBody = {
            "data": {
                "name": contactus.value.fullName,
                "email": contactus.value.email,
                "phone": contactus.value.phone_number,
                "message": contactus.value.message
            }
        };
        this.contactusFail = ""
        this.contactusSuccess = ""
        console.log(contactBody);
        console.log(this.httpClient);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        this.httpClient.post('https://api.keitmea.com/api/contactuses',
        contactBody, headers).subscribe((data)=>{
            this.contactusSuccess = 'Request has been sent successfully'
        },
            (error) => this.contactusFail = (error.error.error.message))
    }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            location: 'Kingdom of Saudi Arabia, United Arab Emirates, Jordan, Egypt and Poland'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '(+996) 54919 8530',
            email: 'info@keitmea.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Fax Number',
            text1: '+962 6516 2247',
            text2: 'Sunday & Saturday: 10:30 - 22:00'
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/contact-img.png'
        }
    ]

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}
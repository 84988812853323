import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-about',
    templateUrl: './homeone-about.component.html',
    styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    // aboutImage: Image[] = [
    //     {
    //         img: 'assets/img/about-home-banner.png'
    //     }
    // ]
    aboutContent: Content[] = [
        {
            subTitle: 'About Us',
            title: 'In numbers',
            paragraphText1: 'The numbers are the best testament to our success story.',
            paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'More About Us',
            defaultBtnLink: 'about-us'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/about-market-img.svg',
            title: '+214',
            subTitle: 'Creative teams'
        },
        {
            icon: 'assets/img/about-market-img.svg',
            title: '+500',
            subTitle: 'Happy Clients'
        },
        {
            icon: 'assets/img/about-rate.svg',
            title: '6',
            subTitle: 'Locations'
        },
        {
            icon: 'assets/img/about-datamgt-operations.svg',
            title: '+975',
            subTitle: 'Projects delivered'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}
<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content">
            <h2>Case Studies Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/case-studies">Case Studies</a></li>
                <li *ngIf="caseStudy">{{caseStudy.attributes.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="case-studies-details-area">
    <div class="case-studies-details-image" *ngIf="caseStudy">
        <img src="https://api.keitmea.com{{caseStudy.attributes.image.data.attributes.url}}" alt="image">
        <div class="overlay-gradient"></div>
        <h2>KEIT MEA puts into practise techniques and procedures to scale up project management, streamline it, and make it more efficient</h2>
    </div>
    <div class="container" >
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12" *ngIf="caseStudy">

                <span class="casesestudy-short-title">{{caseStudy.attributes.short_description}}</span>
                <h2 class="casesestudy-sub-title mb-3">{{caseStudy.attributes.title}}</h2>
                <!-- <h3>{{caseStudy.attributes.title}}</h3> -->

                <div class="row mb-4 col-lg-10 col-sm-12 col-md-6 justify-content-center" style="margin-left: auto; margin-right: auto;"
                        *ngIf="caseStudy.attributes.case_study_inlines.data && caseStudy.attributes.case_study_inlines.data.length > 0">
                    <div class="top-service col-lg-4 col-sm-12 col-md-6 d-flex" *ngFor="let topService of caseStudy.attributes.case_study_inlines.data;">
                        <div class="single-industries-serve-box d-flex align-items-center flex-column">
                            <div class="top-service-img">
                                <img src="https://api.keitmea.com{{topService.attributes.icon.data.attributes.url}}" alt="image">
                            </div>
                            <h5 class="fw-bold mt-4">{{topService.attributes.title}}</h5>
                            <p class="text-center">{{topService.attributes.description}}</p>
                        </div>
                    </div>
                </div>

                <div class="case-studies-details-desc mb-4 col-lg-10 col-sm-12 col-md-6 justify-content-center" style="margin-left: auto; margin-right: auto;">
                    <!-- <span class="sub-title">{{caseStudy.attributes.short_description}}</span>
                    <h3>{{caseStudy.attributes.title}}</h3> -->
                    <div innerHtml={{handleDescWithImages(caseStudy.attributes.description)}}></div>

                    <!-- <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="image">
                                <img [src]="DetailsDesc.img1" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <h3>{{DetailsDesc.title2}}</h3>
                                <ul>
                                    <li *ngFor="let ImportantFacts of caseStudiesDetailsDescImportantFacts;">{{ImportantFacts.title}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p>{{DetailsDesc.paragraph2}}</p>
                    <p>{{DetailsDesc.paragraph3}}</p>
                    <h3>{{DetailsDesc.title3}}</h3>
                    <p>{{DetailsDesc.paragraph4}}</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <!-- <div>
                    <div class="case-studies-details-info">
                        <ul>
                            <li *ngFor="let Info of caseStudiesDetailsInfo;">
                                <div class="icon">
                                    <i class='{{Info.icon}}'></i>
                                </div>
                                <span>{{Info.title}}:</span>
                                {{Info.subTitle}}
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </div>


    <div class="container-fluid other-services" *ngIf="caseStudy && caseStudy.attributes.bottom_case_studies_inlines.data && caseStudy.attributes.bottom_case_studies_inlines.data.length > 0">
        <div class="row mb-4 mt-4 col-9 bottomcaseinline justify-content-center">
           <div class="row mb-4 justify-content-center" style="margin-top: 2rem;">
                <div class="bottom-service col-sm-12 col-md-6 d-flex" *ngFor="let bottomService of caseStudy.attributes.bottom_case_studies_inlines.data;">
                    <div class="single-industries-serve-box row">
                        <div class="col-12 col-sm-3 col-xl-2 mb-3 d-flex justify-content-center">
                            <div class="bottom-service-img">
                                <img src="https://api.keitmea.com{{bottomService.attributes.icon.data.attributes.url}}" alt="image">
                            </div>
                        </div>
                        <div class="bottom-servrice-content col-12 col-sm-9 col-xl-10 px-0">
                            <h5 class="fw-bold text-light">{{bottomService.attributes.title}}</h5>
                            <p class="">{{bottomService.attributes.description}}</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>

</section>

<app-related-case-studies></app-related-case-studies>
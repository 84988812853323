import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';

@Component({
    selector: 'app-homeone-blog',
    templateUrl: './homeone-blog.component.html',
    styleUrls: ['./homeone-blog.component.scss']
})
export class HomeoneBlogComponent implements OnInit {

    public blogs:any;

    constructor(private api:ApiService){
        this.api.getblogs().subscribe(data=>{
            this.blogs=data['data']
        })
    }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our Blog",
            title: 'Latest Valuable Insights',
            paragraphText: 'Discover new perspectives and stay up-to-date with our news.'
        }
    ]
    singleBlogPost: blogPostContent[] = [
        {
            postImg: 'assets/img/blog/alex-bg.png',
            postTitle: 'Six Ways to Make Smarter Decisions',
            postLink: 'blog-details',
            postDate: 'April 30, 2020',
            postAuthorImage: 'assets/img/blog/alex.png',
            postAuthorName: 'Mohamed Amir'
        },
        {
            postImg: 'assets/img/blog/mary-bg.png',
            postTitle: 'The Challenges to Tackle Before You Start With AI',
            postLink: 'blog-details',
            postDate: 'April 28, 2020',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/david-bg.png',
            postTitle: 'Why Organisations Want an Analytics Platform',
            postLink: 'blog-details',
            postDate: 'April 27, 2020',
            postAuthorImage: 'assets/img/blog/david.png',
            postAuthorName: 'Ahmed Ali'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class blogPostContent {
    postImg : string;
    postLink : string;
    postTitle : string;
    postDate : string;
    postAuthorImage : string;
    postAuthorName : string;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-events-details-page',
    templateUrl: './events-details-page.component.html',
    styleUrls: ['./events-details-page.component.scss']
})
export class EventsDetailsPageComponent implements OnInit {

    public event:any;
    public events:any;
    public eventId: any;

    constructor(private api:ApiService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.eventId = params['id'];
            this.api.getevent(this.eventId).subscribe(data=>{
                this.event=data['data']    
            })
            this.api.getevents().subscribe(data=>{
                this.events=data['data']    
            })
        })
    }

    public handleDescWithImages(desc: any) {
        return desc.replaceAll("/uploads", "https://api.keitmea.com/uploads")
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Events Details',
            subTitle: 'Global Conference on Business Management and Economics',
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}
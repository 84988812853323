import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "How it Works",
            title: 'The digital maturity journey! ',
            paragraphText: 'We guide you through this exciting and dynamic journey to improve your business performance and continuously evolve your organization\'s digital capabilities to stay competitive and meet the ever-changing demands of the digital landscape. In order to guide your company to the top of the digital scene, we employ a 6-step methodology.'
        }
    ]
    singleProcessBox: processBoxContent[] = [
        {
            img: 'assets/img/process/num-one.svg',
            title: 'Assess current digital capabilities',
            paragraphText: 'Evaluate the current digital capabilities of your organization and identify areas for improvement.',
            number: '1'
        },
        {
            img: 'assets/img/process/num-two.svg',
            title: 'Define a digital strategy',
            paragraphText: 'Work together with your side to develop a comprehensive digital strategy that outlines your organization\'s goals, priorities, and approach to digital transformation.',
            number: '2'
        },
        {
            img: 'assets/img/process/num-three.svg',
            title: 'Identify and architect solutions',
            paragraphText: 'Design and develop solutions that bridge the gap between current capabilities and business objectives. From a technology spectrum ranging from ERP and EPM to cloud computing, AI, and IOT.',
            number: '3'
        },
        {
            img: 'assets/img/process/num-four.svg',
            title: 'Embrace data-driven decision-making',
            paragraphText: 'Ensure internal processes and decision-making cycle is adopting the new solution, and it empowers them by data to take informed decisions and drive business outcomes.',
            number: '4'
        },
        {
            img: 'assets/img/process/num-five.svg',
            title: 'Promote digital culture',
            paragraphText: 'Ensure that the staff members have the knowledge and skills necessary to capitalize on the changes and support the new procedures. Through training, knowledge transfer sessions, user guides, and tutorials.',
            number: '5'
        },
        {
            img: 'assets/img/process/num-six.svg',
            title: 'Continuously monitor and evaluate progress',
            paragraphText: 'Follow-up continuously to monitor and evaluate progress to ensure that your organization remains on track and that your digital strategy remains relevant.',
            number: '6'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class processBoxContent {
    img : string;
    title : string;
    paragraphText : string;
    number : string;
}
<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="solutions-area pt-100 pb-70">
    <!-- <div class="container">
        <div class="service-content pb-5" >
            <p>
                KEIT's Enterprise Content Management services go beyond just managing content. It not only helps in effectively organizing and managing information, but also in ensuring that the correct version of a document or record is easily accessible. The ultimate goal of KEIT's ECM services is to use content to achieve business objectives. It's a dynamic combination of strategies, methods, and tools that enable organizations to capture, manage, store, preserve, and deliver information in a way that supports key organizational processes throughout their entire lifecycle. In other words, it's not just about managing content, but about using it to drive business success. KEIT's services, which include Content and Case Management, Digital Signature and Electronic Forms, Committee Management, Business Process Management, Electronic Life Cycle, Workflow and Arabic OCR, Correspondence Management Solution, and Digital Asset Management, provide organizations with the necessary tools and capabilities to make this a reality.
            </p>
        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4" *ngFor="let service of services; let i=index">
                <div class="single-solutions-box">
                    <div class="icon">
                        <i class=""></i>
                    </div>
                    <h3><a [routerLink]="['/services-details']" [queryParams]="{id: service.id}">{{service.attributes.title}}</a></h3>
                    <p>{{service.attributes.short_description}}</p>
                    <a [routerLink]="['/services-details']" [queryParams]="{id: service.id}" class="view-details-btn">View Details</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-project-start></app-project-start>
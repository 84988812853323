import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-related-case-studies',
    templateUrl: './related-case-studies.component.html',
    styleUrls: ['./related-case-studies.component.scss']
})
export class RelatedCaseStudiesComponent implements OnInit {

    public caseStudies:any;
    public caseStudy: any;
    public caseStudyId: any;

    constructor(private api:ApiService, private route: ActivatedRoute){
        this.api.getcaseStudies().subscribe(data=>{
            this.caseStudies=data['data']
        })
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.caseStudyId = params['id'];
            this.api.getcaseStudy(this.caseStudyId).subscribe(data=>{
                this.caseStudy=data['data']    
            })
            this.api.getcaseStudies().subscribe(data=>{
                this.caseStudies=data['data']    
            })
        })
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'More Projects You Might Like'
        }
    ]
    singleProjectsBox: singleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img2.png',
            title: 'Movie Recommendation',
            subTitle: 'System Project',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img3.png',
            title: 'Customer Segmentation',
            subTitle: 'Machine Learning',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img4.png',
            title: 'Data Analysis',
            subTitle: 'Web Projects',
            link: 'case-studies-details'
        }
    ]

}
class sectionTitleContent {
    title : string;
}
class singleProjectsContent {
    icon : string;
    img : string;
    title : string;
    subTitle : string;
    link : string;
}
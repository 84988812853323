<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>
    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox1 of contactInfoBox1;">
                    <div class="back-icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox1.icon}}'></i>
                    </div>
                    <h3>{{InfoBox1.title}}</h3>
                    <p *ngIf="setting">{{setting.attributes.address}}</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="contact-info-box" *ngFor="let InfoBox2 of contactInfoBox2;">
                    <div class="back-icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox2.icon}}'></i>
                    </div>
                    <h3>{{InfoBox2.title}}</h3>
                    <p *ngIf="setting">Mobile: <a href="tel:+996549198530">{{setting.attributes.phone}}</a></p>
                    <p *ngIf="setting">E-mail: <a href="mailto:info@keitmea.com">{{setting.attributes.email}}</a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3" >
                <div class="contact-info-box" *ngFor="let InfoBox3 of contactInfoBox3;">
                    <div class="back-icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <div class="icon">
                        <i class='{{InfoBox3.icon}}'></i>
                    </div>
                    <h3>{{InfoBox3.title}}</h3>
                    <p *ngIf="setting"><a href="tel:+96265162247">{{setting.attributes.fax}}</a></p>
                    <p *ngIf="setting" style="min-height: 28.8px;"></p>
                    <!-- <p>{{InfoBox3.text2}}</p> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <p>{{sectionTitleContent.paragraphText}}</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-image" *ngFor="let Image of contactImage;">
                    <img [src]="Image.img" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="row contact-form">
                    <form #contactform="ngForm" (ngSubmit) = "onSubmit(contactform)" >
                        <input type="text" name="fullName" class="col-lg-12 col-md-12 form-control mb-3" placeholder="Your full name" ngModel>

                        <input type="email" name="email" class="col-lg-12 col-md-12 form-control mb-3" placeholder="Your email" ngModel>

                        <input type="text" name="phone_number" class="col-lg-12 col-md-12 form-control mb-3" id="phone_number" placeholder="Your phone number" ngModel>

                        <textarea name="message" class="col-lg-12 col-md-12 form-control mb-3" cols="30" rows="6" placeholder="Your message" ngModel></textarea>

                        <input type="submit" class="default-btn" value="Send Message">
                    </form>
                    <div class="contactus-panel" style="color:green">{{contactusSuccess}}</div>
                    <div class="contactus-panel" style="color:red">{{contactusFail}}</div>
                </div>

            </div>
        </div>
    </div>
</section>

<div id="map">
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.9476519598093!2d-73.99185268459418!3d40.74117737932881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a3f81d549f%3A0xb2a39bb5cacc7da0!2s175%205th%20Ave%2C%20New%20York%2C%20NY%2010010%2C%20USA!5e0!3m2!1sen!2sbd!4v1588746137032!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d486921.0739865169!2d46.8283768585979!3d24.64888017948658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2703cef62e2ec1%3A0xbea4c19fe30d62d4!2z2YXZhti32YLYqSDYp9mE2LHZitin2LYg2KfZhNiz2LnZiNiv2YrYqQ!5e0!3m2!1sar!2seg!4v1676363403103!5m2!1sar!2seg"  width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d7765407.483325549!2d40.89172777217916!3d25.076690330946953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1676381557901!5m2!1sen!2seg"></iframe>
    <!-- <p innerHTML={{setting.attributes.maps_embed_ifram}}></p> -->
</div>
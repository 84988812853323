<div class="main-banner">
    <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/main-banner/slider-04.jpg" class="d-block w-100" alt="slider img">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>Powering The New Leap!</h1>
                    <p>
                        Get professional & reliable research oriented solutions for Data Science and<br>
                        Machine Learning business needs. Enjoy stress free decesion making!
                    </p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <!-- <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a> -->
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/main-banner/slider-03.png" class="d-block w-100" alt="slider img">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>Experienced Professionals</h1>
                    <p>
                        Get professional & reliable research oriented solutions for Data Science and<br>
                        Machine Learning business needs. Enjoy stress free decesion making!
                    </p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <!-- <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a> -->
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/main-banner/slider-01.jpg" class="d-block w-100" alt="slider img">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>Customer Centric</h1>
                    <p>
                        Get professional & reliable research oriented solutions for Data Science and<br>
                        Machine Learning business needs. Enjoy stress free decesion making!
                    </p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <!-- <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a> -->
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <img src="assets/img/main-banner/slider-02.jpg" class="d-block w-100" alt="slider img">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1>Business Oriented</h1>
                    <p>
                        Get professional & reliable research oriented solutions for Data Science and<br>
                        Machine Learning business needs. Enjoy stress free decesion making!
                    </p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <!-- <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a> -->
                    </div>
                </div>
            </div>

        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-lg-5 col-md-12">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <h1 class="wow animate__animated animate__fadeInLeft" data-wow-delay="00ms" data-wow-duration="1000ms">{{Content.title}}</h1>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">{{Content.paragraphText}}</p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube" data-wow-delay="300ms" data-wow-duration="1000ms"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-7 col-md-12">
                <div class="main-banner-animation-image">
                    <img src="assets/img/main-banner/banner-one/img1.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="200ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img2.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img3.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="400ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img4.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="900ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img5.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img6.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="700ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img7.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="800ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img8.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="800ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img9.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="1000ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img10.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="100ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img11.png" class="wow animate__animated animate__fadeInDown" data-wow-delay="300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/img12.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1300ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true">
                    <img src="assets/img/main-banner/banner-one/main-pic.png" alt="image">
                </div>
            </div> -->
        </div>
    </div>
</div>
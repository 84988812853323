<section class="projects-area pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
        </div>

        <div class="row">
            <ng-container *ngFor="let caseStudy of caseStudies; let i=index">
                <div class="col-lg-4 col-md-6" *ngIf="caseStudy.id != caseStudyId">
                    <div class="single-projects-box">
                        <div class="image">
                            <img src="https://api.keitmea.com{{caseStudy.attributes.image.data.attributes.url}}" alt="image" />
    
                            <a [routerLink]="['/case-studies-details']" [queryParams]="{id: caseStudy.id}" class="link-btn"><i class='bx bx-plus'></i></a>
                        </div>
    
                        <div class="content">
                            <h3><a [routerLink]="['/case-studies-details']" [queryParams]="{id: caseStudy.id}">{{caseStudy.attributes.title}}</a></h3>
                            <span>{{caseStudy.attributes.short_description}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
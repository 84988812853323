import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    public caseStudies:any;

    constructor(private api:ApiService){
        this.api.getcaseStudies().subscribe(data=>{
            this.caseStudies=data['data']
        })
    }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'Case study',
            title: 'DHL',
            paragraphText1: 'DHL launched the world\'s largest commercial document archive: the Global Imaging Archive. It houses over 8.5 billion documents, makes them available globally and easily grows with demand.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/dhl2.jpg',
            id: '1'
        },
        {
            subTitle: 'Case study',
            title: 'SEW Eurodrive',
            paragraphText1: 'With Doxis, SEW-EURODRIVE has created a company-wide information platform that can also be used to digitalize cross-disciplinary processes — efficiently and in the local language. The ECM platform has been growing in line with SEW-EURODRIVE\'s needs for over 20 years.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/sew.jpg',
            id: '2'
        },
        {
            subTitle: 'Case study',
            title: 'Lithuanian Traffic Police Service',
            paragraphText1: 'The software robot processes data when violations such as speeding, vehicle operation without technical inspection or insurance are recorded. The whole process is automated from end to end. First, all the necessary information is gathered to start an administrative offense, which is distributed evenly according to the workload, to a responsible official.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/lithuanian-traffic.jpg',
            id: '3'
        },
        {
            subTitle: 'Case study',
            title: 'Automation fuels ambition for SOCAR Turkey',
            paragraphText1: 'This region actually is fueled economies, societies, and innovation across the region ever since. And in 2008, SOCAR moved into Turkey, boosting the country’s energy supply. SOCAR Turkey is dedicated to digital transformation as a strategic priority, and recognizes the benefits it can deliver. It’s especially focused on integrating software into its business processes.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/socar.png',
            id: '4'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
    id: string
}
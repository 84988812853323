import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-events-page',
    templateUrl: './events-page.component.html',
    styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent implements OnInit {

    public events:any;

    constructor(private api:ApiService){
        this.api.getevents().subscribe(data=>{
            this.events=data['data']
        })
    }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Events'
        }
    ]
    singleEvents: singleEventsContent[] = [
        {
            img: 'assets/img/events/img1.jpg',
            date: '8 April, 2021',
            title: 'Join us at our virtual event, SAP',
            location: 'Dubai, UAE'
        },
        {
            img: 'assets/img/events/img2.jpg',
            date: '25 May, 2021',
            title: '11Th Annual Queensland',
            location: 'Jaddah, KSA'
        },
        {
            img: 'assets/img/events/img3.png',
            date: '9 June, 2021',
            title: 'ENR Futuretech Virtual Event',
            location: 'Warsaw, Poland'
        }
    ]

}
class pageTitle {
    title : string;
}
class singleEventsContent {
    img : string;
    date : string;
    title : string;
    location : string;
}
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Solutions',
            title: 'Why Choose KEIT?',
            paragraphText: 'A leading-edge technology powerhouse, fueled by a forward-thinking vision, harnessing the rich history and timeless legacy of the esteemed Zamil Group.'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Customer Centric',
            paragraphText: 'Our steadfast commitment to excellence is unwavering. We are dedicated to providing our clients with nothing less than the highest quality solutions in the IT industry. Our goal is to offer them unparalleled technology that meet their unique needs and exceed their expectations.',
            link: 'services',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Experienced Professionals dedicated to Excellence',
            paragraphText: 'Our team is composed of dynamic and innovative young minds with a deep understanding of the latest technology trends, expertly guided and mentored by seasoned IT professionals with a wealth of experience and dedication to excellence.',
            link: 'services',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'Business-oriented',
            paragraphText: 'As a subsidiary of a leading business group, we possess a deep understanding of growth and strive to achieve it. Our approach is guided by a clear growth roadmap, built around utilizing technology to maximize our clients',
            link: 'services',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}
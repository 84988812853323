<section class="services-area ptb-100 services-home">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2>{{sectionTitleContent.title}}</h2>
            <!-- <p>{{sectionTitleContent.paragraphText}}</p> -->
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6 mb-4" style="max-width: 450px;" *ngFor="let service of services; let i=index">
                <div class="single-services-box">
                    <div class="icon">
                        <img src="https://api.keitmea.com{{service.attributes.home_icon.data.attributes.url}}" alt="image">
                    </div>
                    <h3>
                        <a [routerLink]="['/services-details']" [queryParams]="{id: service.id}">
                            {{service.attributes.title}}
                        </a>
                    </h3>
                    <p innerHtml={{handleDescWithImages(service.attributes.home_desc)}} style="white-space: pre-line; text-align: left;">
                    </p>
                    <a [routerLink]="['/services-details']" [queryParams]="{id: service.id}" class="read-more-btn">
                        Read More
                        <i class="flaticon-right"></i>
                    </a>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="load-more-btn text-center" *ngFor="let loadMore of loadMoreBtn;">
                    <a routerLink="/{{loadMore.link}}" class="default-btn"><i class="{{loadMore.linkIcon}}"></i>{{loadMore.linkText}}<span></span></a>
                </div>
            </div> -->
        </div>
    </div>

    <div class="circle-shape2"><img src="assets/img/shape/circle-shape2.png" alt="image"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
<section class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Events Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/events">events</a></li>
                <li>{{event.attributes.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="events-details-area pb-100">
    <div class="events-details-image">
        <img src="assets/img/events/events-details.png" alt="image">
    </div>

    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12" *ngIf="event">
                <!-- <div class="events-details-header">
                    <ul>
                        <li><i class='bx bx-calendar'></i>Wed, 20 May, 2020</li>
                        <li><i class='bx bx-map'></i>Victoria Road, New York, USA</li>
                        <li><i class='bx bx-time'></i>12.00PM</li>
                    </ul>
                </div> -->

                <div class="events-details-location">
                    <img src="https://api.keitmea.com{{event.attributes.image.data.attributes.url}}" alt="image">
                </div>

                <div class="events-details-desc" innerHtml={{handleDescWithImages(event.attributes.description)}}>
                    <!-- <h3>About The Event</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Where the event?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Who this event is for?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="events-details-info">
                    <ul class="info">
                        <li class="price d-flex">
                            <i class='bx bx-calendar'></i>
                            <span class="ps-2">Wed, 20 May, 2020</span>
                        </li>
                        <li class="price d-flex">
                            <i class='bx bx-map'></i>
                            <span class="ps-2">Riyadh, KSA</span>
                        </li>
                        <li class="price d-flex border-0">
                            <i class='bx bx-time'></i>
                            <span class="ps-2">12:00PM</span>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Pay With</span>
                                <div class="payment-method">
                                    <img src="assets/img/payment/img1.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img2.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img3.png" class="shadow" alt="image">
                                </div>
                            </div>
                        </li> -->
                    </ul>

                    <!-- <div class="btn-box">
                        <a routerLink="/contact" class="default-btn"><i class="flaticon-user"></i>Book Now<span></span></a>
                        <p>You must <a routerLink="/contact">login</a> before register event.</p>
                    </div>

                    <div class="events-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>

                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-events-speakers></app-events-speakers> -->

<section class="events-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <ng-container *ngFor="let event of events; let i=index">
                <div class="col-lg-4 col-sm-6 col-md-6" *ngIf="event.id != eventId">
                    <div class="single-events-box">
                        <div class="image">
                            <a [routerLink]="['/events-details']" [queryParams]="{id: event.id}" class="d-block">
                                <img src="https://api.keitmea.com{{event.attributes.image.data.attributes.url}}" alt="image">
                            </a>
                            <span class="date">{{event.attributes.date}}</span>
                        </div>
    
                        <div class="content">
                            <h3><a [routerLink]="['/events-details']" [queryParams]="{id: event.id}">{{event.attributes.title}}</a></h3>
                            <span class="location"><i class="bx bx-map"></i>{{event.attributes.location}}</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
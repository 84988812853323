import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-related-services',
    templateUrl: './related-services.component.html',
    styleUrls: ['./related-services.component.scss']
})
export class RelatedServicesComponent implements OnInit {

    public service: any;
    public services:any;
    public serviceId: any;

    constructor(private api:ApiService, private route: ActivatedRoute){
        this.api.getservices().subscribe(data=>{
            this.services=data['data']
        })
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.serviceId = params['id'];
            this.api.getservice(this.serviceId).subscribe(data=>{
                this.service=data['data']    
            })
            this.api.getservices().subscribe(data=>{
                this.services=data['data']    
            })
        })
    }

    sectionTitle: sectionTitleContent[] = [
        {
            title: 'More Services You Might Like'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'assets/img/services/Business-process.svg',
            title: 'Data Analytics',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/services/ERP-service.svg',
            title: 'AI & ML Development',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/services/enterprise.svg',
            title: 'Data Science',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}
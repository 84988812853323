<section class="case-study-area">
    <div class="case-study-slides owl-carousel owl-theme">
        <div class="single-case-study-item ptb-100" *ngFor="let singleCaseStudyItemContent of singleCaseStudyItem;">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="case-study-content">
                            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{singleCaseStudyItemContent.subTitle}}</span>
                            <h2>{{singleCaseStudyItemContent.title}}</h2>
                            <p>{{singleCaseStudyItemContent.paragraphText1}}</p>
                            <!-- <a routerLink="/" class="default-btn"><i class="{{singleCaseStudyItemContent.linkIcon}}"></i>{{singleCaseStudyItemContent.linkText}}<span></span></a> -->
                            <a [routerLink]="['/case-studies']" class="default-btn" [queryParams]="{id: singleCaseStudyItemContent.id}"><i class="{{singleCaseStudyItemContent.linkIcon}}"></i>{{singleCaseStudyItemContent.linkText}}<span></span></a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="case-study-image">
                            <!-- <a routerLink="/" class="d-block"><img [src]="singleCaseStudyItemContent.img" alt="image"></a> -->
                            <a [routerLink]="['/case-studies']" class="d-block" [queryParams]="{id: singleCaseStudyItemContent.id}"><img [src]="singleCaseStudyItemContent.img" alt="image"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cse-study-shape">
        <img src="assets/img/case-study/case-study-shape.png" alt="image">
    </div>
</section>
<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content">
            <h2>Service Details</h2>
            <ul *ngIf="service">
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/services">service</a></li>
                <li>{{service.attributes.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="services-details-area">
    <div class="services-details-image d-none d-sm-block">
        <img src="assets/img/services/service-details-banner.png" alt="image">
        <h2>
            {{service.attributes.desc_above_offering}}
        </h2>
    </div>
    <div class="services-details-image2 d-sm-none">
        <h5>
            {{service.attributes.desc_above_offering}}
        </h5>
        <img src="assets/img/services/service-details-banner.png" alt="image">
    </div>
    <div class="container">
        <div class="row justify-content-center" *ngIf="service">
            <div class="col-10">
                <!-- <div class="services-details-image">
                    <img src="https://api.keitmea.com{{service.attributes.image.data.attributes.url}}" alt="image">
                </div> -->

                <div class="services-details-desc" *ngFor="let DetailsDesc of servicesDetailsDesc;">
                    <h2 class="sub-title mb-3">{{service.attributes.title}}</h2>

                    <!-- <h5 class="">{{service.attributes.desc_above_offering}}</h5> -->

                    <!-- <div class="row mb-4">
                        <div class="col-lg-4 col-sm-6 col-md-6 d-flex" *ngFor="let offering of service.attributes.what_we_offer;">
                            <div class="single-industries-serve-box d-flex align-items-center" style="flex: 1 !important;" >
                                {{offering}}
                            </div>
                        </div>
                    </div> -->
                    <div class="row mb-4">
                        <div class="top-service col-lg-4 col-sm-12 col-md-6 d-flex" *ngFor="let topService of service.attributes.top_service_inlines.data;">
                            <div class="single-industries-serve-box d-flex align-items-center flex-column">
                                <div class="top-service-img">
                                    <img src="https://api.keitmea.com{{topService.attributes.icon.data.attributes.url}}" alt="image">
                                </div>
                                <h5 class="fw-bold mt-4">{{topService.attributes.title}}</h5>
                                <p class="text-center">{{topService.attributes.description}}</p>
                            </div>
                        </div>
                    </div>

                    <p class="mb-4" innerHtml={{handleDescWithImages(service.attributes.description)}}></p>
                    <!-- <div class=" container-fluid other-services">
                        <div class="row mb-4">
                            <div class="bottom-service col-sm-12 col-md-6 d-flex" *ngFor="let bottomService of service.attributes.bottom_service_inlines.data;">
                                <div class="single-industries-serve-box row">
                                    <div class="col-2">
                                        <div class="bottom-service-img">
                                            <img src="https://api.keitmea.com{{bottomService.attributes.icon.data.attributes.url}}" alt="image">
                                        </div>
                                    </div>
                                    <div class="bottom-servrice-content col-10 px-0">
                                        <h5 class="fw-bold text-light">{{bottomService.attributes.title}}</h5>
                                        <p class="">{{bottomService.attributes.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <h3>{{DetailsDesc.title3}}</h3> -->

                    <!-- <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6" *ngFor="let Application of servicesDetailsDescApplication;">
                            <div class="single-industries-serve-box">
                                <div class="icon">
                                    <i class="{{Application.icon}}"></i>
                                </div>
                                
                                {{Application.title}}
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="services-details-info">
                    <ul class="services-list">
                        <li *ngFor="let service of services; let i=index">
                            <a [routerLink]="['/services-details']" [queryParams]="{id: service.id}" *ngIf="service.id != serviceId">
                                {{service.attributes.title}}
                            </a>
                        </li>
                    </ul>

                    <div class="services-contact-info">
                        <h3>Contact Info</h3>
                        
                        <ul *ngIf="setting">
                            <li>
                                <div class="icon">
                                    <i class='bx bx-user-pin'></i>
                                </div>
                                <span>Phone:</span>
                                <a href="tel:+996549198530">{{setting.attributes.phone}}</a>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-map'></i>
                                </div>
                                <span>Location:</span>
                                {{setting.attributes.address}}
                            </li>
                            <li>
                                <div class="icon">
                                    <i class='bx bx-envelope'></i>
                                </div>
                                <span>Email:</span>
                                <a href="mailto:info@keitmea.com">{{setting.attributes.email}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class=" container-fluid other-services">
        <div class="container">
            <div class="row justify-content-center" *ngIf="service">
                <div class="col-10">
                    <div class="services-details-desc" *ngFor="let DetailsDesc of servicesDetailsDesc;">
                        <div class="row mb-4">
                            <div class="bottom-service col-sm-12 col-md-6 d-flex" *ngFor="let bottomService of service.attributes.bottom_service_inlines.data;">
                                <div class="single-industries-serve-box row">
                                    <div class="col-12 col-sm-3 col-xl-2 mb-3 d-flex justify-content-center">
                                        <div class="bottom-service-img">
                                            <img src="https://api.keitmea.com{{bottomService.attributes.icon.data.attributes.url}}" alt="image">
                                        </div>
                                    </div>
                                    <div class="bottom-servrice-content col-12 col-sm-9 col-xl-10 px-0">
                                        <h5 class="fw-bold text-light">{{bottomService.attributes.title}}</h5>
                                        <p class="">{{bottomService.attributes.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-related-services></app-related-services>
<div class="navbar-area">
    <div>
        <div class="whats-and-demo">
            <a routerLink="/contact" class="demo-icon"><img src="assets/img/demo-img2.png" alt=""></a>
            <a href="https://api.whatsapp.com/send?phone=966544613666" target="_blank" class="whatsapp-icon"><img src="assets/img/whatsapp-icon.svg" alt=""></a>
            <!-- <a href="https://api.whatsapp.com/send?phone=966544613666&text=Hello this is the starting message" target="_blank" class="whatsapp-icon"><img src="../../assets/img/whatsapp-icon.svg" alt=""></a> -->
        </div>
    </div>
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/keit-logo.svg" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/keit-logo.svg" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/" class="nav-link">Home</a></li>

                        <li class="nav-item"><a routerLink="/services" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu row service-menu">
                                <li class="nav-item" *ngFor="let service of services; let i=index">
                                    <a  [routerLink]="['/services-details']" [queryParams]="{id: service.id}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                        <i class="flaticon-right d-inline-block"></i> {{service.attributes.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu row service-menu">
                                <li class="nav-item" *ngFor="let caseStudy of caseStudies; let i=index">
                                    <a  [routerLink]="['/case-studies-details']" [queryParams]="{id: caseStudy.id}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                                        <i class="flaticon-right d-inline-block"></i> {{caseStudy.attributes.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li> -->

                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>

                        <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                        <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>

                    <!-- <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div>

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Get Started<span></span></a>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <!-- <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div> -->
            
            <!-- <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="search-box">
                                <input type="text" class="input-search" placeholder="Search for anything">
                                <button type="submit"><i class="flaticon-loupe"></i></button>
                            </form>
                        </div>

                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"><i class="flaticon-right"></i>Get Started<span></span></a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-blog-page',
    templateUrl: './blog-page.component.html',
    styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {

    public blogs:any;

    constructor(private api:ApiService){
        this.api.getblogs().subscribe(data=>{
            this.blogs=data['data']
        })
    }

    ngOnInit(): void {
    }
    
    pageTitleArea: pageTitle[] = [
        {
            title: 'Blog'
        }
    ]

    singleBlogPost: blogPostContent[] = [
        {
            postImg: 'assets/img/blog/img4.jpg',
            postTitle: 'Drop by Office Hours for educator tips',
            postLink: 'blog-details',
            postDate: 'June 6, 2021',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/img5.png',
            postTitle: 'Enterprise Content Management',
            postLink: 'blog-details',
            postDate: 'April 5, 2021',
            postAuthorImage: 'assets/img/blog/alex.png',
            postAuthorName: 'Mohamed Amir'
        },
        {
            postImg: 'assets/img/blog/img6.jpg',
            postTitle: '4 ways to better teamwork using',
            postLink: 'blog-details',
            postDate: 'April 5, 2021',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/img7.jpg',
            postTitle: 'Open Text Launches Webroot',
            postLink: 'blog-details',
            postDate: 'May 19, 2021',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/img8.jpg',
            postTitle: 'Finding the blocks of neighboring fields',
            postLink: 'blog-details',
            postDate: 'May 18, 2021',
            postAuthorImage: 'assets/img/blog/alex.png',
            postAuthorName: 'Mohamed Amir'
        },
        {
            postImg: 'assets/img/blog/img9.png',
            postTitle: 'Data into Your Enterprise to Drive Insights',
            postLink: 'blog-details',
            postDate: 'April 15, 2022',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/img10.jpg',
            postTitle: 'Hyland Healthcare showcases',
            postLink: 'blog-details',
            postDate: 'April 4, 2021',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        },
        {
            postImg: 'assets/img/blog/img11.jpg',
            postTitle: 'Monmouthshire Building Society Uses',
            postLink: 'blog-details',
            postDate: 'March 24, 2021',
            postAuthorImage: 'assets/img/blog/alex.png',
            postAuthorName: 'Mohamed Amir'
        },
        {
            postImg: 'assets/img/blog/img12.png',
            postTitle: 'Hyland Named a Strong Performer in',
            postLink: 'blog-details',
            postDate: 'Match 24, 2021',
            postAuthorImage: 'assets/img/blog/mary.png',
            postAuthorName: 'Sarah Mohmady'
        }
    ]

}
class pageTitle {
    title : string;
}
class blogPostContent {
    postImg : string;
    postLink : string;
    postTitle : string;
    postDate : string;
    postAuthorImage : string;
    postAuthorName : string;
}
<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let blog of blogs; let i=index">
                <div class="single-blog-post bg-fffbf5">
                    <div class="post-image">
                        <a [routerLink]="['/blog-details']" [queryParams]="{id: blog.id}">
                            <img src="https://api.keitmea.com{{blog.attributes.image.data.attributes.url}}" alt="image">
                        </a>
                    </div>

                    <div class="post-content">
                        <h3><a class="post-title" [routerLink]="['/blog-details']" [queryParams]="{id: blog.id}">{{blog.attributes.title}}</a></h3>
                        <ul class="post-meta d-flex justify-content-between align-items-center mb-0">
                            <li>
                                <!-- <div class="post-author d-flex align-items-center">
                                    <img [src]="blogPostContent.postAuthorImage" class="rounded-circle" alt="image">
                                    <span>{{blogPostContent.postAuthorName}}</span>
                                </div> -->
                            </li>
                            <li>
                                <i class='flaticon-calendar'></i>
                                {{blog.attributes.date}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a href="#" class="page-numbers">2</a>
                    <a href="#" class="page-numbers">3</a>
                    <a href="#" class="page-numbers">4</a>
                    <a href="#" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</section>
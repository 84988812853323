<app-homeone-banner></app-homeone-banner>

<!-- <app-homeone-video></app-homeone-video> -->

<app-homeone-partner></app-homeone-partner>

<!-- <app-solutions></app-solutions> -->

<app-homeone-about></app-homeone-about>

<!-- <app-homeone-testimonials></app-homeone-testimonials> -->

<app-business-digitally></app-business-digitally>

<app-homeone-services></app-homeone-services>

<!-- <section class="process-area pt-100 pb-70">
    <app-homeone-process></app-homeone-process>
</section> -->

<!-- <app-homeone-case-studies></app-homeone-case-studies> -->

<!-- <section class="scientist-area bg-color pt-100 pb-70">
    <app-homeone-scientist></app-homeone-scientist>
</section> -->

<app-homeone-blog></app-homeone-blog>

<app-project-start></app-project-start>

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-aboutpage-about',
    templateUrl: './aboutpage-about.component.html',
    styleUrls: ['./aboutpage-about.component.scss']
})
export class AboutpageAboutComponent implements OnInit {

    public linebreak = '\n';

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about/img1.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'About Us',
            title: 'Drive Digital Revolution Through Data Science',
            paragraphText1: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
            paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '10 Years',
            subTitle: 'On the market'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '45+',
            subTitle: 'Team members'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '100%',
            subTitle: 'Satisfaction rate'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '80%',
            subTitle: 'Senior scientist'
        }
    ]
    aboutText: Text[] = [
        {
            title: 'Our Mission',
            paragraphText: 'To bring together the brightest minds and highly qualified individuals in the industry to bring immense value to our clients. We hold our staff to high ethical standards, recognizing that our success as a reputable IT specialist stems from the strength of our team and their ability to fulfill our clients\' digital needs wherever they are on the digital maturity scale.',
            featuresList1: 'Activate Listening',
            featuresList2: 'Brilliant minds',
            featuresList3: 'Better. Best. Wow!',
            featuresList4: 'Branding it better!',
            icon: 'flaticon-tick'
        },
        {
            title: 'Our Mission',
            paragraphText: 'To transform organizations’ IT sector into a strategic asset rather than an overhead. KEIT is committed to meeting the needs of our clients while working within their budget constraints to provide the best possible outcome. KEIT strive to play a positive role in the mega changes the kingdom is witnessing and help the digital economy reach the ambitious vision of Kingdom 2030.',
            featuresList1: 'Creating. Results.',
            featuresList2: 'Expect more',
            featuresList3: 'Good thinking',
            featuresList4: 'In real we trust',
            icon: 'flaticon-tick'
        },
        {
            title: 'Who we are',
            paragraphText: '•	Alignment with Vision 2030: We aim to align our business strategies and operations with the aspirations of Saudi Arabia\'s Vision 2030 and play an active role in the country\'s economic and social progress. \n • Dependability: We aim to provide dependable and high-quality services to our clients. \n • Innovation: We will continuously strive to improve and find new ways to fulfill the changing needs of our clients. \n • Honesty: We will conduct our business with transparency and maintain the highest ethical standards. \n • Client Focus: Our priority is the satisfaction and success of our clients, achieved through personalized and responsive service. \n •	Collaboration: We foster a team-oriented and inclusive work environment to better serve our clients.',
            featuresList1: 'Stay real. Always.',
            featuresList2: 'We have you covered',
            featuresList3: 'We turn heads',
            featuresList4: 'Your brand, promoted',
            icon: 'flaticon-tick'
        }
    ]

}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}
class Text {
    title : string;
    paragraphText : string;
    featuresList1 : string;
    featuresList2 : string;
    featuresList3 : string;
    featuresList4 : string;
    icon : string;
}
<section class="business-digitally ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Is your business digitally mature? </h2>
            <p>Generally, the digital maturity means that a business has successfully integrated digital technology into its operations and has developed a culture of continuous digital innovation.</p>
        </div>
        <div class="row">
            <div class="digitally-slides col-12 text-center owl-carousel owl-theme business-question"  >
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Let's start with your first name and your family name</h4>                            
                    <input type="text" id="fullName" name="fullName" class="digitally-input" placeholder="Full Name...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">What's your business/company name?</h4>                           
                    <input type="text" id="companyName" name="companyName" class="digitally-input" placeholder="Type your answer here...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Since when is the business running?</h4>                             
                    <input type="text" id="since" name="since" class="digitally-input" placeholder="Type your answer here...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">What's your rule/title in the company? </h4>                          
                    <input type="text" id="rule" name="rule" class="digitally-input" placeholder="Type your answer here...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Are you looking to enhance an existing process/service or build a new one from scratch? </h4>                         
                    <input type="text" id="enhanceBuild" name="enhance-build" class="digitally-input" placeholder="Type your answer here...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Please tell us more about your requirements ...</h4>                           
                    <input type="text" id="requirements" name="requirements" class="digitally-input" placeholder="Type your answer here...">
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Finally, we want to reach you, what's your email address? </h4>                           
                    <input type="text" id="email" name="email" class="digitally-input" placeholder="Type your answer here...">
                    <!-- <p id="email_error" style="background-color: #f8d7da; color: #172b40">Please enter valid email</p> -->
                    <span id="email_error">Please enter valid email</span>
                </div>
                <div class="single-testimonials-item">
                    <h4 class="carousel-title">Do you prefer to reach you on the phone too? Kindly provide us with your mobile number. </h4>                           
                    <input type="text" id="phone" name="phoneNumber" class="digitally-input" placeholder="Type your answer here...">
                </div>
            </div>
        </div>
    </div>
    <button id="digitalsubmitinquiry" class="submit-inquiry" style="display:none; margin: 10px auto;" (click)="submitInquiry($event)">Submit</button>
    <div class="one" style="color: #fff">{{contactusSuccess}}</div>
    <div class="one" style="color: #ff0000a1">{{contactusFail}}</div>
    <span class="question-msg" id="question-msg1">A few more question</span>
    <span class="question-msg" id="question-msg2">About to complete it ..</span>

    <div class="circle-shape2"><img src="assets/img/shape/circle-shape2.png" alt="image"></div>
</section>
<div class="partner-area pt-2 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">
                <img src="assets/img/star-icon.png" alt="image">
                OUR PARTNERS
            </span>
            <h2>Our Partners</h2>
            <p>We have established strong connections with industry-leading partners, who share our commitment to innovation, quality, and customer satisfaction. Our partnerships enable us to leverage the latest technologies and tools, to develop and implement solutions that meet our client's needs and exceed their expectations:</p>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-2 col-6 col-sm-4 col-md-4" *ngFor="let Item of partnerItem;">
                <div class="single-partner-item">
                    <img [src]="Item.img" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape-left">
        <img src="assets/img/testimonials/client-shape-left.png" alt="image">
    </div>
    <div class="shape-right">
        <img src="assets/img/testimonials/client-shape-right.png" alt="image">
    </div>

    <div class="divider"></div>
</div>
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    public linebreak = '\n';

    public services:any;

    constructor(private api:ApiService){
        this.api.getservices().subscribe(data=>{
            this.services=data['data']
        })
    }

    public handleDescWithImages(desc: any) {
        return desc.replaceAll("/uploads", "https://api.keitmea.com/uploads")
    }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Our Services',
            title: 'We Offer Professional Solutions For Business',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'
        }
    ]
    singleServicesBox: ServicesBoxContent[] = [
        {
            icon: 'assets/img/services/Business-process.svg',
            title: 'Content Services',
            paragraphText: '• Content and case Management. \n'
            +'• Digital Signature and Electronic Forms. \n'
            +'• Committee Management. \n'
            +'• Business Process Management. \n'
            +'• Electronic life Cycle. \n'
            +'• Workflow and Arabic OCR. \n' 
            +'• Correspondence Management Solution. \n'
            +'• Digital Asset Management. \n',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/ERP-service.svg',
            title: 'Robotic Process Automation (RPA)',
            paragraphText: '• Accelerated transformation. \n • Boosted productivity. \n • Improved compliance. \n • Higher accuracy. \n • Greater resilience',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/enterprise.svg',
            title: 'Enterprise project management',
            paragraphText: '• Risk Management. \n • Demand Management. \n • Resource Management. \n • Business Intelligence. \n • Program Management. \n • Financial Management',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/Business-intelligence.svg',
            title: 'Microsoft Platform',
            paragraphText: '• SharePoint.' + '\n' + '• CRM.' + '\n' + '• ERP.' + '\n' + '• Power Platform.',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        },
        {
            icon: 'assets/img/services/low-code.svg',
            title: 'Research & Development',
            paragraphText: 'What we offer? \n  Portals. \n IT Development. \n IT Manpower. \n Mobile Application. \n Customized Solutions. \n Digital Transformation Consultations',
            link: 'services-details',
            linkText: 'Read More',
            linkIcon: 'flaticon-right'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
    linkIcon : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}
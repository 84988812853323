import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import {NgForm} from '@angular/forms';
// import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  constructor(private httpClient: HttpClient) { }
  getcaseStudies()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/case-studies?populate=*`);
    // console.log(data);
    return data;
  }
  getcaseStudy(id) {
    let data =  this.httpClient.get('https://api.keitmea.com/api/case-studies/'+id+'?populate=*&populate=image&populate=case_study_inlines.icon&populate=bottom_case_studies_inlines.icon');
    return data;
  }
  getservices()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/services?populate=*&sort=sorting_number`);
    return data;
  }
  getservice(id) {
    let data =  this.httpClient.get('https://api.keitmea.com/api/services/'+id+'?populate=*&populate=top_service_inlines.icon&populate=bottom_service_inlines.icon');
    return data;
  }
  getblogs()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/blogs?populate=*`);
    return data;
  }
  getblog(id) {
    let data =  this.httpClient.get('https://api.keitmea.com/api/blogs/'+id+'?populate=*');
    return data;
  }
  getblogCategories()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/blog-categories?populate=*`);
    return data;
  }
  getblogTags()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/blog-tags?populate=*`);
    return data;
  }
  getevents()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/events?populate=*`);
    return data;
  }
  getevent(id) {
    let data =  this.httpClient.get('https://api.keitmea.com/api/events/'+id+'?populate=*');
    return data;
  }
  getsetting()
  {
    let data =  this.httpClient.get(`https://api.keitmea.com/api/setting`);
    return data;
  }
  // onSubmit(contactus: NgForm): Observable<contactus> {
  //   console.log(contactus.value);
  //   return this.httpClient.post('http://34.125.46.177:1337/api/contactuses', contactus);
  // }
}
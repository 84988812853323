import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { GalleryPageComponent } from './components/pages/gallery-page/gallery-page.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CaseStudiesPageComponent } from './components/pages/case-studies-page/case-studies-page.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { SolutionsComponent } from './components/common/solutions/solutions.component';
import { HomeoneServicesComponent } from './components/pages/homepage-one/homeone-services/homeone-services.component';
import { HomeoneProcessComponent } from './components/pages/homepage-one/homeone-process/homeone-process.component';
import { HomeoneCaseStudiesComponent } from './components/pages/homepage-one/homeone-case-studies/homeone-case-studies.component';
import { BusinessDigitallyComponent } from './components/pages/homepage-one/business-digitally/business-digitally.component';
import { HomeoneScientistComponent } from './components/pages/homepage-one/homeone-scientist/homeone-scientist.component';
import { HomeoneTestimonialsComponent } from './components/pages/homepage-one/homeone-testimonials/homeone-testimonials.component';
import { HomeonePartnerComponent } from './components/pages/homepage-one/homeone-partner/homeone-partner.component';
import { HomeoneBlogComponent } from './components/pages/homepage-one/homeone-blog/homeone-blog.component';
import { ProjectStartComponent } from './components/common/project-start/project-start.component';
import { HomeoneAboutComponent } from './components/pages/homepage-one/homeone-about/homeone-about.component';
import { HomeoneVideoComponent } from './components/pages/homepage-one/homeone-video/homeone-video.component';
import { HomeoneBannerComponent } from './components/pages/homepage-one/homeone-banner/homeone-banner.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AboutpageAboutComponent } from './components/pages/about-page/aboutpage-about/aboutpage-about.component';
import { AboutpageHistoryComponent } from './components/pages/about-page/aboutpage-history/aboutpage-history.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { RelatedServicesComponent } from './components/pages/services-details-page/related-services/related-services.component';
import { CaseStudiesDetailsPageComponent } from './components/pages/case-studies-details-page/case-studies-details-page.component';
import { RelatedCaseStudiesComponent } from './components/pages/case-studies-details-page/related-case-studies/related-case-studies.component';
import { EventsDetailsPageComponent } from './components/pages/events-details-page/events-details-page.component';
import { EventsSpeakersComponent } from './components/pages/events-details-page/events-speakers/events-speakers.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent,
    // GalleryPageComponent,
    EventsPageComponent,
    ErrorPageComponent,
    CaseStudiesPageComponent,
    FooterComponent,
    PreloaderComponent,
    HomepageOneComponent,
    SolutionsComponent,
    HomeoneServicesComponent,
    HomeoneProcessComponent,
    BusinessDigitallyComponent,
    HomeoneCaseStudiesComponent,
    HomeoneScientistComponent,
    HomeoneTestimonialsComponent,
    HomeonePartnerComponent,
    HomeoneBlogComponent,
    ProjectStartComponent,
    HomeoneAboutComponent,
    HomeoneVideoComponent,
    HomeoneBannerComponent,
    SubscribeComponent,
    NavbarComponent,
    AboutPageComponent,
    AboutpageAboutComponent,
    AboutpageHistoryComponent,
    TeamPageComponent,
    ServicesPageComponent,
    ServicesDetailsPageComponent,
    ContactPageComponent,
    BlogPageComponent,
    BlogDetailsPageComponent,
    RelatedServicesComponent,
    CaseStudiesDetailsPageComponent,
    RelatedCaseStudiesComponent,
    EventsDetailsPageComponent,
    EventsSpeakersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
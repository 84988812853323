<section class="page-title-area">
    <div class="container page-banner">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="projects-area pt-100 pb-70">
    <div class="container page-banner">
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let caseStudy of caseStudies; let i=index">
                <div class="single-projects-box">
                    <div class="image">
                        <img src="https://api.keitmea.com{{caseStudy.attributes.image.data.attributes.url}}" alt="image" />

                        <a [routerLink]="['/case-studies-details']" [queryParams]="{id: caseStudy.id}" class="link-btn"><i class='bx bx-plus'></i></a>
                    </div>

                    <div class="content">
                        <h3 class="fw-bold"><a [routerLink]="['/case-studies-details']" [queryParams]="{id: caseStudy.id}">{{caseStudy.attributes.title}}</a></h3>
                        <!-- <span>{{caseStudy.attributes.short_description}}</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
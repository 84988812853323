import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-homeone-video',
    templateUrl: './homeone-video.component.html',
    styleUrls: ['./homeone-video.component.scss']
})
export class HomeoneVideoComponent implements OnInit {

  @ViewChild("keitVideo", { static: false }) videoplayer: ElementRef;

    constructor() { 

    }

    ngOnInit(): void {
    }

    muteVideoPlayer(){
      var myVideo: any = document.getElementById("keitVideo");
      myVideo.muted = true
      document.getElementById("soundOn").classList.remove('d-none')
      document.getElementById("soundOff").classList.add('d-none')
    }
    unMuteVidPlayer(){
      var myVideo: any = document.getElementById("keitVideo");
      myVideo.muted = false
      document.getElementById("soundOn").classList.add('d-none')
      document.getElementById("soundOff").classList.remove('d-none')

    }

  ngAfterViewInit() {

  }

}
<section class="project-start-area {{sectionClass}} pt-4">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="project-start-image" *ngFor="let Image of projectStartImage;">
                    <img [src]="Image.img" data-speed="0.06" data-revert="true" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="project-start-content" *ngFor="let Content of projectStartContent;">
                    <h2>{{Content.title}}</h2>
                    <p>{{Content.paragraphText}}</p>
                    <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="{{circleShape1Class}}"><img src="assets/img/shape/circle-shape1.png" alt="image"></div>
    <div class="{{vectorShape3Class}}"><img src="assets/img/shape/vector-shape3.png" alt="image"></div> -->
    <!-- <div class="{{vectorShape9Class}}"><img src="assets/img/shape/vector-shape9.png" alt="image"></div> -->
    <!-- <div class="{{vectorShape10Class}}"><img src="assets/img/shape/vector-shape10.png" alt="image"></div> -->
</section>
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-case-studies-page',
    templateUrl: './case-studies-page.component.html',
    styleUrls: ['./case-studies-page.component.scss']
})
export class CaseStudiesPageComponent implements OnInit {

    public caseStudies:any;

    constructor(private api:ApiService){
        this.api.getcaseStudies().subscribe(data=>{
            this.caseStudies=data['data']
        })
    }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Case Studies'
        }
    ]
    singleProjectsBox: singleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img1.jpg',
            title: 'Kuwait’s Ministry',
            subTitle: 'System Project',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img2.jpg',
            title: 'Democratizing Application',
            subTitle: 'Machine Learning',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img3.jpg',
            title: 'Confronting The COVID-19',
            subTitle: 'Web Projects',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img4.jpg',
            title: 'Heathrow Uses',
            subTitle: 'Programming',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img5.png',
            title: 'Onbase Ensures Medicaid',
            subTitle: 'Data Science',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/projects/img6.png',
            title: 'Alaska Health',
            subTitle: 'Science Projects',
            link: 'case-studies-details'
        }
    ]

}
class pageTitle {
    title : string;
}
class singleProjectsContent {
    icon : string;
    img : string;
    title : string;
    subTitle : string;
    link : string;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-blog-details-page',
    templateUrl: './blog-details-page.component.html',
    styleUrls: ['./blog-details-page.component.scss']
})
export class BlogDetailsPageComponent implements OnInit {

    public blog:any;
    public blogs:any;
    public blogId: any;
    public blogCategories:any;
    public blogTags: any;

    constructor(private api:ApiService, private route: ActivatedRoute){
        this.api.getblogCategories().subscribe(data=>{
            this.blogCategories=data['data']    
        })
        this.api.getblogTags().subscribe(data=>{
            this.blogTags=data['data']    
        })
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.blogId = params['id'];
            this.api.getblog(this.blogId).subscribe(data=>{
                this.blog=data['data']    
            })
            this.api.getblogs().subscribe(data=>{
                this.blogs=data['data']    
            })
        })
    }

    public handleDescWithImages(desc: any) {
        return desc.replaceAll("/uploads", "https://api.keitmea.com/uploads")
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Blog Details',
            subTitle: 'The Challenges to Tackle Before You Start With AI'
        }
    ]

}
class pageTitle {
    title : string;
    subTitle : string;
}
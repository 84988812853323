<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <h2>{{sectionTitleContent.title}}</h2>
        </div>

        <div class="row">
            <ng-container *ngFor="let service of services; let i=index" >
            <div class="col-lg-4 col-md-6 col-sm-6 mb-4" *ngIf="service.id != serviceId">
                <div class="single-solutions-box" >
                    <div class="icon">
                        <i class=""></i>
                    </div>
                    <h3><a [routerLink]="['/services-details']" [queryParams]="{id: service.id}">{{service.attributes.title}}</a></h3>
                    <p style="min-height: 214px;">{{service.attributes.short_description}}</p>
                    <a [routerLink]="['/services-details']" [queryParams]="{id: service.id}" class="view-details-btn">View Details</a>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</section>
<footer class="{{footerClass}}">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/keit-logo.svg" alt="logo"></a>
                    <p *ngIf="setting">{{setting.attributes.footer_bio}}</p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/KnowledgeExchangeInformationTechnology" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://twitter.com/keitmea" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="https://www.instagram.com/keitmea/?igshid=YmMyMTA2M2Y%3D" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/keitmea/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="">Home</a></li>
                        <li><a routerLink="/about-us">About Us</a></li>
                        <li><a routerLink="/case-studies">Case Studies</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <!-- <li><a routerLink="/team">Our Team</a></li> -->
                        <li><a routerLink="/services">Our Services</a></li>
                        <li><a routerLink="/events">Our Events</a></li>
                        <li><a routerLink="/blog">Our Blog</a></li>
                        <!-- <li><a routerLink="/">Testimonials</a></li>
                        <li><a routerLink="/services-details">SaaS Solutions</a></li>
                        <li><a routerLink="/services-details">eCommerce</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info" *ngIf="setting">
                        <li><i class='bx bx-map'></i>{{setting.attributes.address}}</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+996549198530">{{setting.attributes.phone}}</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello@wilo.com">{{setting.attributes.email}}</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+96265162247">{{setting.attributes.fax}}</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>Copyright © 2023 .Powered by <a href="https://panarab-media.com/" target="_blank">PAM TECH</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-map"><img src="assets/img/footer-map.png" alt="image"></div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>
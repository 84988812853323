<section class="pt-4 video-sec">
        <div style="padding:56.25% 0 0 0;position:relative;">
                <img src="assets/img/main-banner/video-shape.svg" class="d-none d-lg-block" style="position:absolute;top:-19.8%;object-fit:none;z-index:2;height: 25%;" alt="video-shape">
                <div class="keit-video" style="position:absolute;top:-12.5%;left:0;width:100%;height:100%;background-color: #172b40;">
                        <video #videoPlayer class="keitVideo" id="keitVideo" width="100%" loop="loop" autoplay [muted]="true">
                                <source src="assets/video/keit-video.mp4" type="video/mp4">
                        </video>
                        <div id="soundOn" class="sound-off" (click)="unMuteVidPlayer()">
                                <span>
                                        <img src="assets/video/mute.svg" alt="">
                                        UnMute
                                </span>
                        </div>
                        <div id="soundOff" class="sound-off d-none" (click)="muteVideoPlayer()">
                                <span>
                                        <img src="assets/video/unmute.svg" alt="">
                                        Mute
                                </span>
                        </div>
                </div>
        </div>
</section>
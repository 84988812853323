import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public services:any;
    public caseStudies:any;

    constructor(private api:ApiService){
        this.api.getservices().subscribe(data=>{
            this.services=data['data']
        }),
        this.api.getcaseStudies().subscribe(data=>{
            this.caseStudies=data['data']
        })
    }

    ngOnInit(): void {
    }

}
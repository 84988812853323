import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';

@Component({
    selector: 'app-services-page',
    templateUrl: './services-page.component.html',
    styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

    public services:any;

    constructor(private api:ApiService){
        this.api.getservices().subscribe(data=>{
            this.services=data['data']
        })
    }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Services'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-rocket',
            title: 'Content Services',
            paragraphText: 'KEIT\'s Enterprise Content Management services go beyond just managing content. It not only helps in effectively organizing and managing information, but also in ensuring that the correct version...',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-laptop',
            title: 'Robotic Process Automation (RPA)',
            paragraphText: 'As the world moves towards a more technologically advanced future, automation has become a crucial aspect of business operations...',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-money',
            title: 'Enterprise project management',
            paragraphText: 'The Microsoft Enterprise Project Management Solution revolutionizes the way portfolios of projects are governed and executed throughout their...',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-segmentation',
            title: 'Microsoft Platform',
            paragraphText: 'The Microsoft Platform is a complete set of technologies created to assist enterprises in streamlining their processes and more effectively...',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Research & Development',
            paragraphText: 'The role of IT teams has undergone a significant transformation in recent years, with a focus on providing a secure and collaborative platform for diverse...',
            link: 'services-details',
            linkText: 'View Details'
        },
        // {
        //     icon: 'flaticon-settings',
        //     title: 'Technology',
        //     paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.',
        //     link: 'services-details',
        //     linkText: 'View Details'
        // }
    ]

}
class pageTitle {
    title : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}